import React, { useEffect, useState } from 'react';
import './MainGameAndProvider.css'; // Import the CSS file
import { InfiniteScroll, DotLoading, Modal, Input, Button } from 'antd-mobile';
import useGameData from '../useGameData';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';

const MainGameAndProvider = ({category, onLoginRedirect}) => {

  // const [selectedType, setSelectedType] = useState('');
  const { auth } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [searchModalVisible, setSearchModalVisible] = useState(false); // Modal visibility state
  const [searchKeyword, setSearchKeyword] = useState(''); // Search keyword state

  const { t } = useTranslation();
  
  const {
    providers,
    loadingProvider,
    providerHasMore,
    fetchProviders,
    selectedProvider,
    handleProviderClick,
    categories,
    selectedType,
    setSelectedType,
    loadingCategory,
    selectedCategory,
    handleCategorySelect,
    types,
    loadingType,
    games,
    loadingGame,
    hasMore,
    fetchGames,
    isCategorySet,
    handleTypeClick,
    handleSearch
  } = useGameData(null, "SLOT" , true);

  useEffect(() => {
    handleCategorySelect(category);
  },[category]);

  useEffect(() => {
    if (isCategorySet) {
      fetchProviders();
      fetchGames();
    }
  }, [isCategorySet]);

  const loadMoreGames = () => {
    
    // console.log("game hasMore = "+hasMore);
    // console.log("game loadingGame = "+loadingGame);
    // console.log("game isCategorySet = "+isCategorySet);
    if (hasMore && !loadingGame && isCategorySet) {
      fetchGames();
    }
  };

  const loadMoreProviders = () => {
    // console.log("provider hasMore = "+providerHasMore);
    // console.log("provider loadingProvider = "+loadingProvider);
    // console.log("provider isCategorySet = "+isCategorySet);
    if (providerHasMore && !loadingProvider && isCategorySet) {
      fetchProviders();
    }
  };

  const loadMoreContent = () => {
    loadMoreGames();
    loadMoreProviders();
  };

  function openNewTab(url) {
    window.open(url, '_blank');
  };

  const handlePlay = async(gameUniqueId) => {
    if(auth.isAuthenticated){
      const raw = JSON.stringify({
        "username": auth.username,
        "gameID": gameUniqueId,
        "language": "th",
        "device" : "desktop",
        "ip": "127.0.0.1"
      });
  
      const requestOptions = {
        method: "POST",
        headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
        body: raw,
        redirect: "follow"
      };
  
      try {
        const response = await fetch(apiUrl+'/member/startgame', requestOptions);
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
  
        // Check if data.data and data.data.uri exist
        if (data.data && data.data.uri) {
          // console.log("Opening new tab with URI:", data.data.uri);
          openNewTab(data.data.uri);
        } else {
          console.error("URI not found in response data:", data);
        }
        
      } catch (error) {
        console.error("Login failed:", error);
      }
    }
    else{
      onLoginRedirect();
    }
  }

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {hasMore ?? (
          <>
            <span>Loading more games...</span>
            <DotLoading />
          </>
        )}
      </>
    );
  };

  const onSearchClick = () => {
    // Handle search functionality here. You can trigger the fetchGames with the searchKeyword.
    console.log("Search keyword:", searchKeyword);
    handleSearch(searchKeyword);
  };

  return (
    <div className="main-game-container">
      
      <div className="game-type-container">
        {/* {types.length > 1 && types.map((type, index) => (
          <button onClick={() => {setSelectedType(type.name); handleTypeClick(type.name);}} 
            className={`type-button ${selectedType === type.name ? "selected" : ""}`}
            key={type.id}>
              {t(type.name)}
          </button>
        ))} */}

        {/* <button className="search-button" onClick={() => setSearchModalVisible(true)}>
          Search
        </button> */}
        
      </div>
      
      <div className="search-container">
        <input
          className="search-input"
          type="text" 
          value={searchKeyword} 
          onChange={(e) => setSearchKeyword(e.target.value)} /* Update the state */
          placeholder="ค้นหาเกมส์" 
        />
        <button className="search-button" onClick={onSearchClick}>ค้นหา</button>
      </div>
      

      <div className="game-and-provider-container">
        <div className="sidebar-container">
          {providers.map((provider, index) => (
            <img className={`provider-button ${selectedProvider === provider.name ? "selected" : ""}`} 
                  key={provider.id}  src={provider.logoMobileUrl} 
                  onClick={() => handleProviderClick(provider.name)} 
                  alt={provider.name}>
            </img>
          ))}
        </div>

        <div className="main-game-and-provider-container">
          {games.length === 0 ? (
              <p>No games available</p>
            ) : (
              games.map((game) => (
                <img key={game.uniqueId} src={game.image.verticalUrl} alt={game.name} onClick={() => handlePlay(game.uniqueId)}/>
              ))
          )}

        <InfiniteScroll loadMore={loadMoreContent} hasMore={hasMore || providerHasMore} threshold={300}>
          <InfiniteScrollContent hasMore={hasMore || providerHasMore} />
        </InfiniteScroll>
        </div>
      </div>
      
    </div>
  );
};

export default MainGameAndProvider;